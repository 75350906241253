import { buttonClassNames, ButtonVariants, classNames } from '@/utils/class-names';
import { isLocalDevelopment } from '@/utils/local-development';
import { reportSentryWarning } from '@/utils/sentry';
import { forwardRef } from 'react';

// Match root-relative (internal) links that are missing a trailing slash
// Regex adapted from: https://stackoverflow.com/a/64252045/1546808
const rootRelativeLinkMissingTrailingSlashRegex = /^\/(?:.*\/)?[^/.]+$/;

type LinkProps = {
  href: string;
  asButton?: ButtonVariants;
  disabled?: boolean;
  download?: string;
  target?: '_blank';
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, asButton, disabled = false, download, target, className, onClick, children }, ref) => {
    const allClassNames = classNames(
      asButton ? 'inline-block' : '',
      asButton ? buttonClassNames[asButton] : 'text-blue-600 underline',
      disabled ? 'opacity-75 pointer-events-none' : '',
      className
    );

    // Flag links to internal pages without trailing slash
    // Trailing slash is enforced by Netlify for consistency & SEO (Pretty URL option), so we should
    // avoid linking to pages w/out trailing slash as it'll result in a 301 redirect
    if (isLocalDevelopment() && rootRelativeLinkMissingTrailingSlashRegex.test(href)) {
      reportSentryWarning(`Internal URL missing trailing slash`, { href });
    }

    return (
      <a
        href={href}
        className={allClassNames}
        ref={ref}
        download={download}
        target={target}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
);

export { Link };
